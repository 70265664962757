export const config = {
  apiServer: "https://cameras.ljkjkf.com/api",
  // apiServer: "http://localhost:8999",
  authServer: "https://cameras.ljkjkf.com/auth",
  // authServer: "http://localhost:8998",
  amap: {
    key: "49a9cd4155542cdacf49c642c6adcc19",
    version: "2.0",
  },
};
