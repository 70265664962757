import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/Home/Home";
import { AlarmImage } from "./pages/Alarm/AlarmImage";
import moment from "moment";
import "moment/locale/zh-cn";
import locale from "antd/lib/locale/zh_CN";
import { ConfigProvider } from "antd";
import { FlvPlayer } from "./Components/FlvPlayer";
import {TestTalk} from "./pages/TestTalk/TestTalk";

const WindSensorIndex = lazy(
  () => import("./pages/WindSensor/WindSensorIndex")
);
const CameraExhibitionPanel = lazy(
  () => import("./pages/Camera/CameraExhibitionPanel")
);
const LoginIndex = lazy(() => import("./pages/Login/LoginIndex"));
const DeviceIndex = lazy(() => import("./pages/Device/DeviceIndex"));
const DeviceMap = lazy(() => import("./pages/DeviceList/DeviceMap"));
const CameraIndex = lazy(() => import("./pages/Camera/CameraIndex"));
const AlarmIndex = lazy(() => import("./pages/Alarm/AlarmIndex"));
const AccountIndex = lazy(() => import("./pages/Account/AccountIndex"));
const CustomerIndex = lazy(() => import("./pages/Customer/CustomerIndex"));
const PlaceIndex = lazy(() => import("./pages/Place/PlaceIndex"));
const CameraPlayer = lazy(() => import("./pages/CameraList/CameraPlayer"));
const SourceIndex = lazy(() => import("./pages/Source/SourceIndex"));
const SimCardIndex = lazy(() => import("./pages/SimCard/SimCardIndex"));
const LocatorIndex = lazy(() => import("./pages/Locator/LocatorIndex"));
const BracketIndex = lazy(() => import("./pages/Bracket/BracketIndex"));
const BurglarAlarmIndex = lazy(
  () => import("./pages/BurglarAlarm/BurglarAlarmIndex")
);
const BatteryIndex = lazy(() => import("./pages/Battery/BatteryIndex"));
const SolarPanelIndex = lazy(
  () => import("./pages/SolarPanel/SolarPanelIndex")
);

moment.locale("zh-cn");

const App = () => {
  return (
    <ConfigProvider locale={locale}>
      <Suspense fallback={<div>suspense</div>}>
        <Routes>
          <Route path={"/alarmImage"} element={<AlarmImage />} />
          <Route path={"/testTalk"} element={<TestTalk />} />
          <Route path="/" element={<CameraExhibitionPanel />} />
          <Route path={"/login"} element={<LoginIndex />} />
          <Route path={"/admin"} element={<Home />}>
            <Route path="deviceIndex" element={<DeviceIndex />} />
            <Route path="deviceMap" element={<DeviceMap />} />
            <Route path="cameraIndex" element={<CameraIndex />} />
            <Route path="alarmIndex" element={<AlarmIndex />} />
            <Route path="accountIndex" element={<AccountIndex />} />
            <Route path="customerIndex" element={<CustomerIndex />} />
            <Route path="placeIndex" element={<PlaceIndex />} />
            <Route path="cameraPlayer" element={<CameraPlayer />} />
            <Route path="alarmImage" element={<AlarmImage />} />
            <Route path="sourceIndex" element={<SourceIndex />} />
            <Route path="simCardIndex" element={<SimCardIndex />} />
            <Route path="locatorIndex" element={<LocatorIndex />} />
            <Route path="windSensorIndex" element={<WindSensorIndex />} />
            <Route path="bracketIndex" element={<BracketIndex />} />
            <Route path="burglarAlarmIndex" element={<BurglarAlarmIndex />} />
            <Route path="batteryIndex" element={<BatteryIndex />} />
            <Route path="solarPanelIndex" element={<SolarPanelIndex />} />
            <Route path={"flv"} element={<FlvPlayer />} />
          </Route>
        </Routes>
      </Suspense>
    </ConfigProvider>
  );
};

export default App;
