import { Menu } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import React from "react";
import styled from "@emotion/styled";
import { getToken } from "../../Services/Auth";
import { useMount } from "ahooks";

export const Home = () => {
  const navigate = useNavigate();

  useMount(() => {
    if (!getToken()) {
      navigate("/login");
      return <></>;
    }
  });

  return (
    <FullScreenContainer>
      <Menu
        style={{ width: 180 }}
        onClick={(event) => {
          console.log(event);
          navigate(`/admin/${event.key}`)
        }}
        mode={"inline"}
        items={[
          {
            key: "deviceIndex",
            label: "设备管理",
          },
          {
            key: "deviceMap",
            label: "设备地图",
          },
          {
            key: "customerIndex",
            label: "客户信息管理",
          },
          {
            key: "placeIndex",
            label: "部位信息管理",
          },
          {
            key: "cameraIndex",
            label: "摄像头管理",
          },
          // {
          //   key: "alarmIndex",
          //   label: "告警信息管理",
          // },
          // {
          //   key: "accountIndex",
          //   label: "萤石云账号管理",
          // },
          // {
          //   key: "sourceIndex",
          //   label: "来源信息管理",
          // },
          // {
          //   key: "simCardIndex",
          //   label: "物联网卡信息管理",
          // },
          // {
          //   key: "locatorIndex",
          //   label: "定位器信息管理",
          // },
          // {
          //   key: "windSensorIndex",
          //   label: "风向风速传感器管理",
          // },
          // {
          //   key: "bracketIndex",
          //   label: "支架管理",
          // },
          // {
          //   key: "burglarAlarmIndex",
          //   label: "防盗器管理",
          // },
          // {
          //   key: "batteryIndex",
          //   label: "蓄电池管理",
          // },
          // {
          //   key: "solarPanelIndex",
          //   label: "太阳能板管理",
          // },
        ]}
      />
      <MainContent>
        <Outlet />
      </MainContent>
    </FullScreenContainer>
  );
};

const FullScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 10px;
`;

const MainContent = styled.div`
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  height: 100%;
  overflow: hidden;
`;
