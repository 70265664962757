import flvjs from "flv.js";
import { useEffect, useRef } from "react";

export const FlvPlayer = () => {
  const ref = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (ref.current) {
      const player = flvjs.createPlayer({
        type: "flv",
        url: "http://123.60.89.123:8090/live/c3213a0dcb222dea_SubStreamProfileToken_000_640_480.flv?Token=DA7480B454189DDEFB73AC5B9D2C7BAB&DeviceNo=202112310016",
      });
      player.attachMediaElement(ref.current);
      player.load();
      player.play();
    }
  }, [ref]);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <video muted={true} style={{ width: "100%", height: "100%" }} ref={ref}></video>
    </div>
  );
};
