import { useList } from "../../Services/Hooks";
import { CameraPlayer } from "../../Components/CameraPlayer";

export const TestTalk = () => {
  const { data: cameraData } = useList("cameras", {
    deviceSerial: "D70063052",
  });

  if (cameraData?.list) {
    return (
      <div style={{ height: "100%" }}>
        <CameraPlayer camera={cameraData.list[0]}></CameraPlayer>
      </div>
    );
  }

  return <div>no main</div>;
};
