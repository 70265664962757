import {AxiosError} from "axios";
import {FormItemType, MyFormItem} from "../Types/Types";
import {Dayjs} from "dayjs";
import {message} from "antd";

// 异常统一处理
export const parseError = (error: AxiosError) => {
  if (
    !error ||
    !error.response ||
    !error.response.headers ||
    !error.response.status ||
    !error.response.data
  ) {
    return "系统错误";
  }

  // 表单验证错误
  if (
    error.response.status === 400 &&
    error.response.data.type === "ValidationProblem"
  ) {
    return Object.keys(error.response.data.errors)
      .map((key) => error.response?.data.errors[key][0])
      .join(" / ");
  }

  if (error.response.status === 401) {
    return "鉴权失败，请重新登录";
  }

  // 普通客户端错误
  if (
    error.response.status === 400 &&
    error.response.data.type === "BasicProblem"
  ) {
    return error.response.data.detail;
  }

  // 普通服务器错误
  if (
    error.response.status === 500 &&
    error.response.data.type === "BasicProblem"
  ) {
    return error.response.data.detail;
  }

  return "其他错误";
};

export const formValuesFormat = (
  formItems: MyFormItem[],
  values: { [key: string]: string | number | object | boolean | undefined }
) => {
  const formattedValues = { ...values };
  formItems
    ?.filter(
      (item) =>
        item.formItemType === FormItemType.TimePicker ||
        item.formItemType === FormItemType.DatePicker ||
        item.formItemType === FormItemType.DateTimePicker
    )
    .forEach((item) => {
      if (values[item.name]) {
        formattedValues[item.name] = (values[item.name] as Dayjs).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
    });
  formItems?.filter(item => item.formItemType === FormItemType.Cascader).forEach(item => {
    item.multiName?.forEach((name, index) => {
      formattedValues[name] = (values[item.name] as string[])[index]
    })
  })
  return formattedValues;
};

export const isArrayNullOrEmpty = (list: Array<any> | null | undefined) => {
  return !!(list && list.length);
};

export const showSuccess = async (content: string) => {
  await message.success({
    content,
    className: "custom-class",
    style: {
    },
    duration: 1
  });
};
