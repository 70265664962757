import React from "react";
import "./index.css";
import "./assets/iconfont/iconfont.css";
import "antd/dist/antd.min.css";
import zhCN from "antd/lib/locale/zh_CN";
import { ConfigProvider } from "antd";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "dayjs/locale/zh-cn";
import dayjs from "dayjs";
import { QueryClient, QueryClientProvider } from "react-query";

dayjs.locale("zh-cn");

const queryClient = new QueryClient();

render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);
