import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { HttpMethod } from "../Types/Types";
import {config} from "../config";

const ACCESS_TOKEN_KEY = "__app_access_token__";

const AUTHENTICATION_BASE_URL = config.authServer
const AUTHENTICATION_URL = "connect/token";
const GRANT_TYPE = "password";
const CLIENT_ID = "cameras";
const CLIENT_SECRET = "20531901";

export const getToken = () => window.sessionStorage.getItem(ACCESS_TOKEN_KEY);

export const login = async (username: string, password: string) => {
  const config = {
    baseURL: AUTHENTICATION_BASE_URL,
    url: AUTHENTICATION_URL,
    method: HttpMethod.Post,
    data: qs.stringify({
      username,
      password,
      grant_type: GRANT_TYPE,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    }),
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };

  return axios
    .request<any, AxiosResponse<any>>(config)
    .then((response) => {
      const accessToken = response.data.access_token;
      window.sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      return accessToken;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const logout = () => window.sessionStorage.removeItem(ACCESS_TOKEN_KEY);
