import {
  BaseModel,
  HttpMethod,
  PageParams,
  SearchParams,
} from "../Types/Types";
import {
  DEFAULT_ORDER_FIELD,
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
} from "../Common/Consts";
import { useState } from "react";
import { DataResponse, http } from "./Http";
import { config } from "../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { message } from "antd";
import { parseError, showSuccess } from "../Common/Utility";
import { AxiosError } from "axios";

export const useList = (modelName: string, searchParams?: SearchParams) => {
  const queryKey = [modelName, { searchParams }];

  console.log(searchParams)
  return {
    queryKey,
    ...useQuery<DataResponse, Error>(
      queryKey,
      () => http(`${config.apiServer}/${modelName}`, HttpMethod.Get, searchParams),
      {
        onSuccess: () => {
          console.log(`${modelName}列表已加载`);
        },
        onError: async (error) => {
          await message.error(parseError(error as AxiosError));
        },
      }
    ),
  };
};

export const usePageList = (
  modelName: string,
  defaultValues?: { pageParams?: PageParams; searchParams?: SearchParams }
) => {
  const [pageParams, setPageParams] = useState<PageParams>(
      defaultValues?.pageParams ?? {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: DEFAULT_PAGE_SIZE,
        orderField: DEFAULT_ORDER_FIELD,
      }
    ),
    [searchParams, setSearchParams] = useState<SearchParams>(
      defaultValues?.searchParams ?? {}
    ),
    queryKey = [modelName, { pageParams, searchParams }];

  return {
    queryKey,
    pageParams,
    searchParams,
    setPageParams,
    setSearchParams,
    ...useQuery<DataResponse, Error>(
      queryKey,
      () =>
        http(`${config.apiServer}/${modelName}`, HttpMethod.Get, {
          ...pageParams,
          ...searchParams,
        }),
      {
        onSuccess: async () => {
          await showSuccess("列表已加载");
        },
        onError: async (error) => {
          await message.error(parseError(error as AxiosError));
        },
      }
    ),
  };
};

export const useAdd = <T extends BaseModel>(modelName: string) => {
  const queryClient = useQueryClient();
  return useMutation<DataResponse, Error, T>(
    (model: T) => http(modelName, HttpMethod.Post, model),
    {
      onSuccess: () => queryClient.invalidateQueries(modelName),
      onError: async (error) => {
        await message.error(parseError(error as AxiosError));
      },
    }
  );
};

export const useEdit = <T extends BaseModel>(modelName: string) => {
  const queryClient = useQueryClient();
  return useMutation<DataResponse, Error, T>(
    (model: T) => http(`${modelName}/${model.id}`, HttpMethod.Put, model),
    {
      onSuccess: () => queryClient.invalidateQueries(modelName),
      onError: async (error) => {
        await message.error(parseError(error as AxiosError));
      },
    }
  );
};

export const useDelete = (modelName: string) => {
  const queryClient = useQueryClient();
  return useMutation<DataResponse, Error, string>(
    (modelId: string) => http(`${modelName}/${modelId}`, HttpMethod.Delete, {}),
    {
      onSuccess: () => queryClient.invalidateQueries(modelName),
      onError: async (error) => {
        await message.error(parseError(error as AxiosError));
      },
    }
  );
};
