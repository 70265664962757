import { Image } from "antd";
import { useRequest } from "ahooks";
import { getAlarmService } from "../../Services/AlarmService";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const AlarmImage = () => {
  const [params] = useSearchParams();
  const { data, run: getAlarm } = useRequest(getAlarmService, {
    manual: true,
  });

  useEffect(() => {
    params && getAlarm(params.get("id") || "");
  }, [params, getAlarm]);

  if (data) {
    return (
      <Image src={data.imageUrl} style={{ width: "100%" }} preview={false} />
    );
  }
  return <></>;
};
