export enum HttpMethod {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE",
  Patch = "PATCH",
  Head = "HEAD",
}

export enum FormItemType {
  ReadOnly = "readonly",
  Input = "input",
  Select = "select",
  Cascader = "Cascader",
  InputDigit = "input.digit",
  InputNumber = "input.number",
  DatePicker = "date.picker",
  TimePicker = "time.picker",
  DateTimePicker = "date.time.picker",
}

export enum ReturnCode {
  OK = 1,
  Error = 0,
}

export interface PageParams {
  pageIndex: number;
  pageSize: number;
  orderField?: string;
}

export type SearchParams = {
  [key: string]: string | number | boolean | object | undefined;
};

export interface SearchItem {
  key: string;
  label: string;
  value: string | number | boolean | undefined;
  type: FormItemType;
}

export interface BaseModel {
  id: string;
}

export interface ProjectType {
  ptguid: string;
  ptname: string;
  remark: string;
}

export declare type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
    }
  : any;

export interface MyControl {
  label: string;
  method: () => void;
  isLoading: boolean;
}

export interface MyFormItem {
  label: string;
  name: string;
  multiName?: string[],
  formItemType: FormItemType;
  required: boolean;
  message?: string;
  value?: string | number | boolean | undefined;
  data?: { [key: string]: string | number | object | null | undefined }[];
  labelField?: string;
  valueField?: string;
  childrenField?: string;
  onChange?: (event: any) => void;
}

export declare type AlignType = "left" | "center" | "right";

export declare type FixedType = "left" | "right" | boolean;

export interface MyColumn {
  title: string;
  width?: string;
  dataIndex?: string | string[];
  render?: any;
  sorter?: boolean;
  align?: AlignType;
  fixed?: FixedType;
}
