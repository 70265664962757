import axios from "axios";
import { config } from "../config";

export const getAlarmListService = async (
  pageIndex: number,
  pageSize: number,
  searchParams: any
) => {
  const response = await axios.get(`${config.apiServer}/alarms`, {
    params: {
      pageIndex,
      pageSize,
      ...searchParams,
    },
  });
  return response.data;
};

export const getAlarmService = async (id: string) => {
  const response = await axios.get(`${config.apiServer}/alarms/${id}`)
  return response.data
}

export const getAlarmTypeListService = async () => {
  const response = await axios.get(`${config.apiServer}/alarms/alarmTypes`);
  return response.data;
};
