import { Camera } from "../Types/Camera";
import styled from "@emotion/styled";
import { EZUIKitPlayer } from "ezuikit-js";
import { useSize, useTimeout, useUnmount } from "ahooks";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { http } from "../Services/Http";
import { HttpMethod } from "../Types/Types";

export const CameraPlayer = ({ camera }: { camera: Camera }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const size = useSize(containerRef);

  const [player, setPlayer] = useState<any>(null);

  const { data: token } = useQuery(
    ["token", { accountId: camera.device?.accountId }],
    () =>
      http("/devices/token", HttpMethod.Get, {
        accountId: camera.device?.accountId,
      }),
    {
      enabled: !!camera.device?.accountId,
    }
  );

  useEffect(() => {
    if (player) {
      player.destroy();
    }
    if (size && token) {
      setPlayer(
        new EZUIKitPlayer({
          id: camera.id,
          audio: 1,
          accessToken: token,
          url: `ezopen://open.ys7.com/${camera.deviceSerial}/${camera.channelNo}.live`,
          width: size?.width,
          height: size?.height,
          // template: "voice",
          // plugin: ["talk"],
          handleError: (error: any) => {
            console.log(error, "error");
          },
        })
      );
    }
  }, [size, token]);

  useTimeout(() => {
    player.openSound().then(() => {
      console.log("opensound");
    });
  }, 10000);

  useUnmount(() => {
    if (player) {
      player.stop().then(() => {
        player.destroy().then(console.log);
      });
    }
  });

  return (
    <Container ref={containerRef}>
      <div id={camera.id}></div>
      <DisplayPanel>{camera.name}</DisplayPanel>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  border: 1px solid #91d5ff;
  box-shadow: 0 0 10px #91d5ff;
  position: relative;
`;

const DisplayPanel = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: black;
  color: white;
  padding: 5px;
`;
