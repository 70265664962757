import axios, { AxiosResponse } from "axios";
import { HttpMethod } from "../Types/Types";
import { message } from "antd";
import { config } from "../config";
import { getToken } from "./Auth";

const APP_BASE_URL = config.apiServer;

interface Config {
  method: HttpMethod;
  url: string;
  baseURL: string;
  headers: {
    Authorization: string;
  };
  params?: any;
  data?: any;
}

export interface DataResponse {
  list: any;
  total: number;
}

export const http = async (url: string, method: HttpMethod, data: any) => {
  const token = getToken();
  if (!token) {
    await message.error("请您登录后在进行操作！");
    return Promise.reject({ code: 401, message: "鉴权失败" });
  }

  const config: Config = {
    method: method,
    url: url,
    baseURL: APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (method === HttpMethod.Get) {
    config.params = {
      ...data,
    };
  } else {
    config.data = {
      ...data,
    };
  }

  return axios
    .request<DataResponse, AxiosResponse<DataResponse>>(config)
    .then((response) => {
      return response.data;
    });
};
